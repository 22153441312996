<template>
  <v-dialog v-model="dialog" class="ma-16" persistent>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-card class="rounded-xl pa-8">
      <v-card-title>
        {{ getHeadLine(entity) }} importieren
      </v-card-title>
      <v-container v-if="loaded && !finished">
        <v-row class="">
          <v-col cols="12" md="10" lg="6">
            <input type="file" @change="onChange"/>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12">
            <xlsx-read :file="file">
              <xlsx-sheets>
                <template #default="{sheets}">
                  <label v-if="file"> Tabellenblatt auswählen:
                  </label>
                    <select v-model="selectedSheet" class="tertiary pa-1 ps-2 pe-2 selectC" v-if="file">
                      <option disabled value="">Tabellenblatt wählen</option>
                      <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                        {{ sheet }}
                      </option>
                    </select>
                </template>
              </xlsx-sheets>

              <!--<xlsx-table :sheet="selectedSheet" />-->
              <xlsx-json :sheet="selectedSheet" @parsed="setFile">
                <template #default="">
                  <div>
                  </div>
                </template>
              </xlsx-json>

            </xlsx-read>
          </v-col>
        </v-row>
        <hr class="mt-6 mb-6" v-if="selectedSheet">
        <h3 class="mb-6" v-if="selectedSheet">Identifizierte Felder</h3>
        <p v-if="selectedSheet">Ordne die Spalten aus deiner Excel den entsprechenden Feldname zu.</p>
        <v-row v-for="(fieldInApp, fieldName) of fieldNames" :key="fieldName" class="hover">
          <v-col>
            {{ fieldName }}
          </v-col>
          <v-col>
            <select v-model="fieldNames[fieldName]" class="selectC tertiary pa-1 ps-2 pe-2">
              <option disabled value="">Bitte Feld zuordnen</option>
              <option v-for="field of availableFields" :key="field.value"  :label="field.text">
                {{ field.value }}
              </option>
            </select>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        Daten werden importiert: {{totalFinished }} / {{ fileV.length }}<br>
        Fehler: {{errors}}<br>
        Erfolgreich: {{successes}}<br>
        <v-progress-linear
            v-if="!finished"
            indeterminate
            height="8px"
            rounded
            color="primary"
        ></v-progress-linear>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="" v-if="!finished" @click="$emit('hideDialog')">Abbrechen</v-btn>
        <v-btn v-if="fileV && !finished" class="primary" @click="importSheet">Importieren</v-btn>
        <v-btn v-else-if="!finished" class="primary" @click="importSheet" disabled>Importieren</v-btn>
        <v-btn v-if="finished" class="primary" @click="$emit('hideDialog')">Fertig</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getAllFieldsForEntity} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
import {XlsxRead, XlsxSheets, XlsxJson} from "vue-xlsx/dist/vue-xlsx.es.js"
import {mapGetters} from "vuex";
import {toJson} from "cli-highlight";
const delay = ms => new Promise(res => setTimeout(res, ms));
import {error, msgObj} from "@/helper/helper";

export default {
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    Message
  },
  data() {
    return {
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{name: "SheetOne", data: [{c: 2}]}],
      collection: [{a: 1, b: 2}],
      fileV: null,
      sheetV: null,
      fieldNames: {},
      availableFields: getAllFieldsForEntity(this.entity),
      loaded:true,
      totalFinished: 0,
      finished: false,
      successes: 0,
      errors: 0,
      message: msgObj(),
    };
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  props: {
    dialog: Boolean,
    entity: String,
  },
  watch:{
    totalFinished(newCount) {
      if(newCount >= this.fileV.length){
        this.loaded = true
        this.finished = true
      }
    }
  },
  methods: {
    toJson,
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    getHeadLine(entity) {
      switch (entity) {
        case 'location':
          return 'Locations'
        case 'vendor':
          return 'Dienstleister'
        case 'customer':
          return 'Kunden'
        case 'wedding':
          return 'Hochzeiten'
      }
    },
    setFile(event) {
      this.fileV = event
      this.getFieldNames()
      for(let field of this.availableFields){
          if(this.fieldNames[field.value] !== null && this.fieldNames[field.value] !== undefined){
            this.fieldNames[field.value] = field.value
          }else if(this.fieldNames[field.text] !== null && this.fieldNames[field.text] !== undefined){
            this.fieldNames[field.text] = field.value
          }
      }
    },
    async importSheet() {
      if(this.fileV.length < 500){
        this.loaded = false
        let newFieldValues = []
        let fieldNames = this.fieldNames
        for (let row of this.fileV) {
          let newRow = {}
          Object.keys(row).forEach((key) => {
            if (fieldNames[key]) {
              let realField = fieldNames[key]
              newRow[realField] = row[key]
            }
          })
          newFieldValues.push(newRow)
        }

        for (let row of newFieldValues) {
          switch (this.entity) {
            case 'vendor':
              this.$store.dispatch('vendor/createVendor', {
                uid: this.user.id,
                newVendor: row
              }).catch(() => {
                this.errors = this.errors + 1
              }).then(()=>{
                this.successes = this.successes + 1
              }).finally(()=>{
                this.totalFinished = this.totalFinished + 1
              })
              break;
            case 'customer':
              this.$store.dispatch('customer/createCustomer', {
                uid: this.user.id,
                newCustomer: row
              }).catch(() => {
                this.errors = this.errors + 1
              }).then(()=>{
                this.successes = this.successes + 1
              }).finally(()=>{
                this.totalFinished = this.totalFinished + 1
              })
              break;
            case'location':
              this.$store.dispatch('locations/createLocation', {
                uid: this.user.id,
                newLocation: row
              }).catch(() => {
                this.errors = this.errors + 1
              }).then(()=>{
                this.successes = this.successes + 1
              }).finally(()=>{
                this.totalFinished = this.totalFinished + 1
              })
              break;
            case'wedding':
              this.$store.dispatch('wedding/createWedding', {
                uid: this.user.id,
                newWedding: row
              }).catch(() => {
                this.errors = this.errors + 1
              }).then(()=>{
                this.successes = this.successes + 1
              }).finally(()=>{
                this.totalFinished = this.totalFinished + 1
              })
              break;
          }
          await delay(100)
        }
      }else{
        this.message = error('Die Excel-Tabelle darf maximal 500 Zeilen enthalten.')
      }
    },
    getFieldNames() {
      let fieldNames = {}
      Object.keys(this.fileV[0]).forEach(function (key) {
        fieldNames[key] = ''
      })
      this.fieldNames = fieldNames
    },
  }
}
;

</script>
<style>
.selectC{

}
.selectC:hover{
background-color: lightgray !important;
  cursor: pointer;
}

.hover:hover{
  background-color: lightgray !important;

}
</style>
